<template>
  <section id="seccao-entrada">
    <header>
      <div class="header-coluna-esq">
        <h2 class="title-with-strap white">{{ area_data.name }}</h2>

        <div class="breadcrumb-wrapper">
          <div class="breadcrumb">
            <router-link to="/home" class="bcRouter">Home</router-link>
          </div>
        </div>
      </div>

      <div class="header-coluna-dir">
        <!-- <div
          id="icon-cart-header-div"
          @click="goToCart()"
          style="color: #fff !important"
        >
          <i id="icon-search-header" class="fas fa-shopping-cart"></i>
          <img
            id="icon-cart-header"
            src="@/assets/imagens/icone-carrinho.svg"
          />
          <span class="count-cart-items" v-if="cartItems > 0">{{
            cartItems
          }}</span>
        </div> -->
        <div @click="searchBoxShow()" style="color: #fff !important">
          <i id="icon-search-header" class="fas fa-search"></i>
        </div>
        <div v-if="user_name" @click="updateUserShow()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
        <div v-if="!user_name" @click="loginForm()">
          <i id="icon-user-header" class="far fa-user"></i>
        </div>
        <div class="texto-font-2 white">Emotional Technology</div>
        <!-- <div class="title-with-strap white">Guide</div> -->
      </div>
    </header>

    <AreaUser />
    <SearchBox />

    <Area />
    <main class="area">
      <div class="main-inner-wrapper">
        <h3 class="white h1">{{ area_data.title }}</h3>
        <div class="white font-size-24 font-weight-500">
          {{ area_data.description }}
        </div>
      </div>
    </main>
    <footer>
      <div v-if="categories.length > 1" class="menu-container swiper-container">
        <div class="menu swiper-wrapper">
          <div
            v-for="item in categories"
            :key="item.ordem"
            class="swiper-slide"
          >
            <div class="menu-item">
              <router-link
                class="links"
                :key="'/categoria/' + item.id"
                :to="{
                  path:
                    '/categoria/' +
                    item.id +
                    '-' +
                    item.name.replaceAll(' ', '-').toLowerCase(),
                }"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="categories.length <= 1"
        class="menu-container swiper-container"
      >
        <div class="menu swiper-wrapper">
          <div v-for="item in categories" :key="item.ordem">
            <div class="menu-item">
              <router-link
                class="links"
                :key="'/categoria/' + item.id"
                :to="{
                  path:
                    '/categoria/' +
                    item.id +
                    '-' +
                    item.name.replaceAll(' ', '-').toLowerCase(),
                }"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="menu-swiper-button-next"></div>
      <div class="menu-swiper-button-prev"></div>
    </footer>

    <div class="loadingLayer" :style="'display: ' + loadingStyle">
      <img src="/assets/imagens/loading.gif" />
      <h3>A carregar...</h3>
    </div>
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>
<script type="text/javascript" src="@/assets/ficheiros/scripts.js"></script>

<script>
  import Area from "@/components/Area.vue";
  import AreaUser from "@/components/Area.vue";
  import SearchBox from "@/components/SearchBox.vue";

  export default {
    name: "Areas",
    components: {
      Area,
      AreaUser,
      SearchBox,
    },
    props: ["backdoor"],
    data() {
      return {
        area_id: this.$route.params.id,
        area_data: {},
        categories: [],
        loadingStyle: "flex",
        breadcrums: {},
        user_name: window.sessionStorage.getItem("name"),
      };
    },
    computed: {
      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      loginForm: async function () {
        document.querySelector("#area-screen").style.display = "block";

        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }, 200);
      },
      async goToCart() {
        this.$router.push("/cart");
      },
      collect_page_data: async function () {
        var operation = "areas/" + this.area_id;
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        this.loadingStyle = "flex";

        var area_data = await this.call_api_get(url, headers);

        this.area_data = area_data[0];
        // console.log(area_data.categories, "cat");
        this.categories = area_data.categories;
        // console.log(this.categories);
        var parsedobj = JSON.parse(JSON.stringify(this.categories));
        var objectvalue = Object.values(parsedobj);
        this.categories = objectvalue.sort(function (a, b) {
          var nameA = Number(a.ordem);
          var nameB = Number(b.ordem);
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.breadcrums = {
          area_name: null,
          area_id: null,
        };

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 500);
      },
      // sortOrder: function (arr) {
      //   // Set slice() to avoid to generate an infinite loop!
      //   let list = [];
      //   list.push(arr);
      //   return list.slice().sort(function (a, b) {
      //     return a.ordem - b.ordem;
      //   });
      // },
      call_api_post: async function (url, headers, method) {
        try {
          var response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(headers, null, 2),
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = window.sessionStorage.name;
        document.querySelector("#novo-email").value =
          window.sessionStorage.email;

        document.querySelector("#area-screen").style.display = "block";

        if (document.querySelector("#icon-user-header")) {
          document.querySelector("#icon-user-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen").className = "home-section-1";
        }, 200);
      },
      logOut: async function () {
        window.sessionStorage.clear();
        this.$cookies.remove("username");
        this.$cookies.remove("token");
        window.location.href = "/";
      },
      searchBoxShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#area-screen-search").style.display = "block";

        if (document.querySelector("#icon-search-header") != null) {
          document.querySelector("#icon-search-header").style =
            "color: #EA8822 !important;";
        }
        setTimeout(function () {
          document.querySelector("#area-screen-search").className =
            "home-section-1";
        }, 200);
      },
    },
    created() {
      this.collect_page_data();
      // let isLogged = window.sessionStorage.getItem("user_id");
      // const result = isLogged == null ? false : true;

      // if (!result) {
      //   window.location.href = "/login";
      // } else {
      //   console.log("session is valid");
      // }

      var menuSlider = new Swiper("footer .menu-container.swiper-container", {
        navigation: {
          nextEl: ".menu-swiper-button-next",
          prevEl: ".menu-swiper-button-prev",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        },
        loop: true,
        infinite: true,
        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
        },
      });
    },
    updated() {
      var menuSlider = new Swiper("footer .menu-container.swiper-container", {
        navigation: {
          nextEl: ".menu-swiper-button-next",
          prevEl: ".menu-swiper-button-prev",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        },
        loop: true,
        infinite: true,
        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 1,
          },
        },
      });

      var color_code = this.area_data.color_code;

      var styleNode = document.createElement("style");
      styleNode.type = "text/css";
      var styleText = document.createTextNode(
        ":root {--cor-principal: " +
          color_code +
          " !important;--cor-1: " +
          color_code +
          " !important;}"
      );
      styleNode.appendChild(styleText);

      document.getElementsByTagName("head")[0].appendChild(styleNode);

      // let isLogged = window.sessionStorage.getItem("user_id");
      // const result = isLogged == null ? false : true;

      // if (!result) {
      //   window.location.href = "/login";
      // } else {
      //   console.log("session is valid");
      // }
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>

<style scoped>
  #icon-user-header,
  #icon-search-header {
    font-size: 2.8vh !important;
    margin-right: 15px !important;
    display: block !important;
    transition: 700ms;
    margin-left: 0 !important;
  }
  #icon-user-header:hover,
  #icon-search-header:hover,
  #icon-cart-header:hover {
    cursor: pointer;
  }
  #icon-cart-header {
    width: 33px;
    height: 33px;
  }
  #icon-cart-header-div {
    margin-right: 10px;
    position: relative;
    width: 38px;
    height: 34px;
  }
  #seccao-entrada footer .menu {
    align-items: center;
  }
  header .header-coluna-esq {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  header .header-coluna-esq h2 {
    margin-bottom: 1px !important;
  }
  header .breadcrumb-wrapper {
    display: block;
    width: 100%;
    margin-top: 8px;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }

  .links:hover {
    color: var(--cor-principal);
    text-decoration: underline;
  }

  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .area-top {
      margin-top: 0px !important;
    }
  }

  @media only screen and (max-width: 640px) {
    .seccao-entrada header {
      flex-flow: column;
    }

    .seccao-entrada header .header-coluna-esq,
    .seccao header .header-coluna-dir {
      width: 100%;
      justify-content: flex-start;
    }

    .seccao-entrada header .header-coluna-dir {
      margin-top: 10px;
    }

    #icon-user-header-area {
      margin-right: 0px !important;
      position: absolute;
      color: #fff !important;
      top: 15px;
      right: 15px;
    }
  }
</style>

<style>
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    .area-top {
      margin-top: 0px !important;
      height: 100vh !important;
    }
  }
</style>
