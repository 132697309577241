var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"seccao-entrada"}},[_c('header',[_c('div',{staticClass:"header-coluna-esq"},[_c('h2',{staticClass:"title-with-strap white"},[_vm._v(_vm._s(_vm.area_data.name))]),_c('div',{staticClass:"breadcrumb-wrapper"},[_c('div',{staticClass:"breadcrumb"},[_c('router-link',{staticClass:"bcRouter",attrs:{"to":"/home"}},[_vm._v("Home")])],1)])]),_c('div',{staticClass:"header-coluna-dir"},[_c('div',{staticStyle:{"color":"#fff !important"},on:{"click":function($event){return _vm.searchBoxShow()}}},[_c('i',{staticClass:"fas fa-search",attrs:{"id":"icon-search-header"}})]),(_vm.user_name)?_c('div',{on:{"click":function($event){return _vm.updateUserShow()}}},[_c('i',{staticClass:"far fa-user",attrs:{"id":"icon-user-header"}})]):_vm._e(),(!_vm.user_name)?_c('div',{on:{"click":function($event){return _vm.loginForm()}}},[_c('i',{staticClass:"far fa-user",attrs:{"id":"icon-user-header"}})]):_vm._e(),_c('div',{staticClass:"texto-font-2 white"},[_vm._v("Emotional Technology")])])]),_c('AreaUser'),_c('SearchBox'),_c('Area'),_c('main',{staticClass:"area"},[_c('div',{staticClass:"main-inner-wrapper"},[_c('h3',{staticClass:"white h1"},[_vm._v(_vm._s(_vm.area_data.title))]),_c('div',{staticClass:"white font-size-24 font-weight-500"},[_vm._v(" "+_vm._s(_vm.area_data.description)+" ")])])]),_c('footer',[(_vm.categories.length > 1)?_c('div',{staticClass:"menu-container swiper-container"},[_c('div',{staticClass:"menu swiper-wrapper"},_vm._l((_vm.categories),function(item){return _c('div',{key:item.ordem,staticClass:"swiper-slide"},[_c('div',{staticClass:"menu-item"},[_c('router-link',{key:'/categoria/' + item.id,staticClass:"links",attrs:{"to":{
                path:
                  '/categoria/' +
                  item.id +
                  '-' +
                  item.name.replaceAll(' ', '-').toLowerCase(),
              }}},[_vm._v(_vm._s(item.name))])],1)])}),0)]):_vm._e(),(_vm.categories.length <= 1)?_c('div',{staticClass:"menu-container swiper-container"},[_c('div',{staticClass:"menu swiper-wrapper"},_vm._l((_vm.categories),function(item){return _c('div',{key:item.ordem},[_c('div',{staticClass:"menu-item"},[_c('router-link',{key:'/categoria/' + item.id,staticClass:"links",attrs:{"to":{
                path:
                  '/categoria/' +
                  item.id +
                  '-' +
                  item.name.replaceAll(' ', '-').toLowerCase(),
              }}},[_vm._v(_vm._s(item.name))])],1)])}),0)]):_vm._e(),_c('div',{staticClass:"menu-swiper-button-next"}),_c('div',{staticClass:"menu-swiper-button-prev"})]),_c('div',{staticClass:"loadingLayer",style:('display: ' + _vm.loadingStyle)},[_c('img',{attrs:{"src":"/assets/imagens/loading.gif"}}),_c('h3',[_vm._v("A carregar...")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }